import { Badge, Box, Flex, Text } from "@chakra-ui/react";

interface Props {
  title: string
  subtitle: string
  date: string
  category: string
  categoryColor: string
}

export default function MyBadge(props: Props) {
  return (
    <Flex whiteSpace={"nowrap"} marginRight={18}>
      <Box ml='3'>
        <Text fontSize='sm'>{props.date}</Text>
          <Text fontWeight='bold'>
            <Badge mr='1' colorScheme={props.categoryColor}>
              {props.category}
            </Badge>
            {props.title}
          </Text>
        <Text fontSize='sm'>{props.subtitle}</Text>
      </Box>
    </Flex>
  )
}

// <Avatar src='https://bit.ly/sage-adebayo' />