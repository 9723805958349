import { Box, Center, chakra, Icon, Link, useColorModeValue, Image } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import {Link as ReactLink, NavigateFunction, useNavigate } from "react-router-dom";
import {isMobile} from 'react-device-detect';
import climberCourse from "../../assets/header.jpg"

const baseUrl: string = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? "http://192.168.1.146:7104" : window.location.origin;


export function HeroHome() {
  const navigate = useNavigate()
  
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  /*
  const [videoStream, setVideoStream] = useState<MediaStream | undefined>();
  
  const onNext = useCallback(async (res: HttpClientResponse) => {
      const data = await res.message;
      console.log(res)
      console.log(videoStream)
      setVideoStream(data);
  }, [setVideoStream]);
  useStream(baseUrl + '/api/Video', {onNext});
  /*/
const isMobileLayout = width <= 991;
  
  if(isMobileLayout) {
    console.log("Mobile")
    return HeroMobile(navigate, isMobileLayout)
  }
  console.log("Not Mobile")
  return HeroDesktop(navigate, isMobileLayout)
}


function HeroDesktop(navigate: NavigateFunction, isMobileLayout: boolean) {
  const bg = useColorModeValue("white", "gray.800");
  return (
    <Box pos="relative" overflow="hidden" bg={bg} mt={10}>
      <Box maxW="7xl" mx="auto">
        <Box
          pos="relative"
          pb={{
            base: 8,
            sm: 16,
            md: 20,
            lg: 28,
            xl: 32,
          }}
          maxW={{
            lg: "2xl",
          }}
          w={{
            lg: "full",
          }}
          zIndex={1}
          bg={bg}
          border="solid 1px transparent"
        >
          <Icon
            display={{
              base: "none",
              lg: "block",
            }}
            position="absolute"
            right={0}
            top={0}
            bottom={0}
            h="full"
            w={48}
            color={bg}
            transform="translateX(50%)"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </Icon>
          <Box
            mx="auto"
            maxW={{
              base: "7xl",
            }}
            px={{
              base: 4,
              sm: 6,
              lg: 8,
            }}
            mt={{
              base: 10,
              sm: 12,
              md: 16,
              lg: 20,
              xl: 28,
            }}
          >
            <Box
              w="full"
              textAlign={{
                sm: "center",
                lg: "left",
              }}
              justifyContent="center"
              alignItems="center"
            >
              {HeaderText(isMobileLayout)}
              {SubHeaderText(isMobileLayout)}
              <Box
                mt={{
                  base: 5,
                  sm: 8,
                }}
                display={{
                  sm: "flex",
                }}
                justifyContent={{
                  sm: "center",
                  lg: "start",
                }}
                fontWeight="extrabold"

              >
                {ButtonBar(navigate)}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        position={{
          lg: "absolute",
        }}
        top={{
          lg: 0,
        }}
        bottom={{
          lg: 0,
        }}
        right={{
          lg: 0,
        }}
        w={{
          lg: "50%",
        }}
        border="solid 1px transparent"
      >
        {Player(isMobileLayout)}
      </Box>
    </Box>
  )
}

function HeroMobile(navigate: NavigateFunction, isMobileLayout: boolean) {
  
  return (
    <Box>
      <Box pos="relative" overflow="hidden" mt={0}>
      <Box
        position={{
          lg: "absolute",
        }}
        top={{
          lg: 0,
        }}
        bottom={{
          lg: 0,
        }}
        right={{
          lg: 0,
        }}
        w={{
          lg: "50%",
        }}
        border="solid 1px transparent"
        //minH={"200px"}
      >
        {Player(isMobileLayout)}

      </Box>
      <Box maxW="7xl" mx="auto" >
        <Box
          pos="relative"
          pb={{
            base: 8,
            sm: 16,
            md: 20,
            lg: 28,
            xl: 32,
          }}
          maxW={{
            lg: "2xl",
          }}
          w={{
            lg: "full",
          }}
          
          zIndex={1}

          border="solid 1px transparent"
        >
          <Icon
            display={{
              base: "none",
              lg: "block",
            }}
            position="absolute"
            right={0}
            top={0}
            bottom={0}
            h="full"
            w={48}
            transform="translateX(50%)"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </Icon>
          <Box
            mx="auto"
            maxW={{
              base: "7xl",
            }}
            px={{
              base: 4,
              sm: 6,
              lg: 8,
            }}
            mt={{
              base: 10,
              sm: 12,
              md: 16,
              lg: 20,
              xl: 28,
            }}
            alignItems="end"
          >
            <Box
              w="full"
              textAlign={{
                sm: "center",
                lg: "left",
              }}
              justifyContent="center"
              alignItems="center"
              //paddingTop={"10em"}
            >
                {HeaderText(isMobileLayout)}
                {SubHeaderText(isMobileLayout)}
              <Box
                mt={{
                  base: 5,
                  sm: 8,
                }}
                display={{
                  base: "none",
                  sm: "flex",
                }}
                justifyContent={{
                  sm: "center",
                  lg: "start",
                }}
                fontWeight="extrabold"

              >
                {ButtonBar(navigate)}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      
    </Box>    
      <Center>
        <Box
          mt={{
            base: 5,
            sm: 5,
          }}
          display={{
            base: "flex",
            sm: "none"
          }}
          justifyContent={{
            base: "start",
          }}
          fontWeight="extrabold"

        >

          {ButtonBar(navigate)}
        </Box>
      </Center>
    </Box>
  )
}

function ButtonBar(navigate: NavigateFunction) {
  return (
    <>
    <Box rounded="full" shadow="md">
    <Link
      w="full"
      display="flex"
      alignItems="center"
      justifyContent="center"
      border="solid 1px transparent"
      fontSize={{
        base: "md",
        md: "lg",
      }}
      as={ReactLink}
      to={"/prices"}
      onClick={(event: any) => {event.preventDefault(); console.log("Nav 5"); navigate("/prices")}}
      rounded="md"
      color="white"
      bg="#FF980C"
      _hover={{
        bg: "#F7A331",
      }}
      px={{
        base: 10,
        md: 10,
      }}
      py={{
        base: 4,
        md: 4,
      }}
      cursor="pointer"
    >
      Se priser
    </Link>
  </Box>
  <Box mt={[0, 0]} ml={[3, 3]}>
    <chakra.a
      w="full"
      display="flex"
      alignItems="center"
      justifyContent="center"
      px={{
        base: 10,
        md: 10,
      }}
      py={{
        base: 4,
        md: 4,
      }}
      border="solid 1px transparent"
      fontSize={{
        base: "md",
        md: "lg",
      }}
      as={ReactLink}
      to={"/about#contact"}
      onClick={(event: any) => {event.preventDefault(); console.log("Nav 6"); navigate("/about#contact")}}
      rounded="md"
      color="brand.700"
      bg="gray.200"
      _hover={{
        bg: "gray.100",
      }}
      cursor="pointer"
    >
      Kontakt oss
    </chakra.a>
  </Box>
  </>
  )
}

function HeaderText(isMobileLayout: boolean) {
  return (
    <chakra.h1
      fontSize={{
        base: "4xl",
        sm: "5xl",
        md: "6xl",
      }}
      letterSpacing="tight"
      lineHeight="short"
      fontWeight="extrabold"
      color={isMobileLayout ? "white" : "gray.900"}
      _dark={{
        color: "white",
      }}
      
    >
      <chakra.span
        display={{
          base: "block",
          xl: "inline",
        }}
      >
        Velkommen til {" "}
      </chakra.span>
      <chakra.span
        as='u'
        display={{
          base: "block",
          xl: "inline",
        }}
        color="#FF980C"
        _dark={{
          color: "brand.400",
        }}
      >
        Moss klatresenter!
      </chakra.span>
    </chakra.h1>
  )
}

function SubHeaderText(isMobileLayout: boolean) {
  return (
    <chakra.p
      mt={{
        base: 3,
        sm: 5,
        md: 5,
      }}
      fontSize={{
        sm: "lg",
        md: "xl",
      }}
      maxW={{
        sm: "xl",
      }}
      mx={{
        sm: "auto",
        lg: 0,
      }}
      color={isMobileLayout ? "white" : "gray.500"}
    >
      Et splitter nytt 220 kvm buldreanlegg på Jeløya i Moss
    </chakra.p>
  )
}

function Player(isMobileLayout: boolean) {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  
  function VideoPlayer() {
    return (
      <ReactPlayer 
      className='react-player'
      type="video/mp4"
      url={baseUrl + '/api/Video'}
      muted={true}
      loop
      autoPlay
      playing={true}
      playsinline
      height={"none"}
      width={"auto"}
      fileconfig={{
        forceHLS: !isSafari, 
        forceVideo: true,
        hlsVersion: '0.12.4',
        attributes: { playsinline: true, autoPlay: true, muted: true }
      }}
      onError={(error) => console.log(error)}
    />
    )
  }
  
  function ImageComponent() {
    return (
      <Box 
        h={"auto"}
        w="100%">
        <Image
          fit="cover"
          src={climberCourse}
          alt=""
        />
      </Box>
    )
  }
 
  if(isMobileLayout) {
    return (
      <Box position={"absolute"} alignItems={"center"} justifyContent={"center"} top={"0"} width={"100%"}  h={["full", "full", "full", "full"]} >
        {isMobile ? ImageComponent() : VideoPlayer()}
      </Box>
    )
  } else {
    return (
      <Box position={"relative"} alignItems={"center"} justifyContent={"center"} top={"0"} width={"100%"}  h={["full", "full", "full", "full"]} >
        {isMobile ? ImageComponent() : VideoPlayer()}
      </Box>
    )
  }
}