import { Center, Image } from "@chakra-ui/react";
import * as React from "react"
import Footer from "../components/Widgets/Footer";
import LayoverText from "../components/Widgets/LayoverText";
import ProfileCard from "../components/Widgets/Shared/ProfileCard";
import ContactFormComponent from "../components/Widgets/ContactFormComponent";
import { Inquiry, submitInquiry } from "../network/network";
import { useEffect } from "react";

import climberKnot from "../assets/Resepsjon.jpg"

import amundImage from "../assets/employee/amund.jpg"
import aneImage from "../assets/employee/ane.png"
import henryImage from "../assets/employee/henry.jpg"
import rasmusImage from "../assets/employee/rasmus.jpg"
import gudrunImage from "../assets/employee/gudrun.jpg"

function AboutPage() {
  
    
  async function submitInquiryCallback(inquiry: Inquiry): Promise<boolean> {
    return await submitInquiry(inquiry);
  }
  
  useEffect(() => {
    const href = window.location.href
    if (href.includes("#")) {
      const id = `${href.substring(href.indexOf("#") + 1)}`
      const anchor = document.getElementById(id)
      if(anchor){
          anchor.scrollIntoView({ behavior: "smooth" })
      }
    }
  }, [])

  return (
    <>
      <Center>
        <Image className="rounded-borders" src={climberKnot} w={['100%', '100%', '90%', '1000px']} marginTop={["-10px", "-10px", "40px"]} />
      </Center>
      <LayoverText shadow={true} offset={true} header="Om oss" subHeader="Moss klatresenter" content={[
        "Moss klatresenter er et nyetablert senter i Værftsgata 22 på Jeløy. Her deler vi lokaler med PP+ og Moss Padel. Vi er stolte av å være det første klatresenteret i Moss som tilbyr et fullverdig anlegg for buldring. Motivasjonen for å starte senteret er vår elsk for klatring. Det er god trening, fysisk og mentalt utfordrende, sosialt og ikke minst veldig morsomt! Idéen om Moss klatresenter kom etter flere års «frustrasjon» over at vi måtte reise utenbys for å klatre. En by som Moss trenger et klatretilbud og målet vårt er å dele dette med flest mulig.",
      ]} />
      <ProfileCard name="Gudrun Reitan" imageRef={gudrunImage} age="27 år" origin="Moss" description="Etter å ha vært en del av klatremiljøet i Oslo, ønsker vi å ta med dette fellesskapet hjem til Moss. Vi vil at flere skal få oppleve denne lavterskel-idretten, en fantastisk idrett for absolutt alle!"></ProfileCard>
      <ProfileCard name="Henry A. Isegran" imageRef={henryImage} age="28 år" origin="Moss, Jeløy" description="Ønsker å skape et godt klatretilbud for mossinger, enten de har klatret fra før eller ønsker å teste det ut."></ProfileCard>
      <ProfileCard name="Rasmus Andreas Isegran" imageRef={rasmusImage} age="29 år" origin="Moss, Jeløy" description="Har klatret innimellom på hobbybasis, ganske ny i game’et. Min hovedmotivasjon for buldrehallen er å skape et kult sted der folk liker å henge."></ProfileCard>
      <ProfileCard name="Ane Høydal Rognsaa" imageRef={aneImage} age="27 år" origin="Tromsø" description="Jeg har klatret av og på i mange år. Jeg har savnet å kunne klatre i Moss, men endelig er muligheten her og jeg gleder meg til å ta en del av det. Klatring er en aktivitet der man kan oppnå mestring helt uavhengig av nivå og i trygge omgivelser."></ProfileCard>
      <ProfileCard name="Amund Fevang" imageRef={amundImage} age="29 år" origin="Moss, Jeløy" description="Ble bitt av klatrebasillen for noen år siden og har drevet med trad- og sportsklatring i tillegg til buldring siden det. Liker de fysiske og mentale utfordringene klatring gir. I tillegg er klatring supersosialt og det er et tilbud jeg har savnet her hjemme i flere år. Håper vi sees!"></ProfileCard>
      <ContactFormComponent id={"contact"} sendInquryCallback={submitInquiryCallback}></ContactFormComponent>
      <Footer />
    </>

  )
}

export default AboutPage;