import {
  Box,
  Button,
  Divider,
  Heading,
  List,
  Stack,
  Text,
  Link,
  VStack,
} from '@chakra-ui/react';
import { Price } from '../../../network/network';

const PackageTier = (price: Price) => {
  return (
    <Stack
      p={3}
      py={3}
      justifyContent={{
        base: 'flex-start',
        md: 'space-between',
      }}
      direction={{
        base: 'column',
        md: 'row',
      }}
      alignItems={{ md: 'center' }}>
        
      <VStack align={"left"} w={"40%"}>
        <Heading size={'md'}>{price.name}</Heading>
        <Text>{price.description}</Text>
      </VStack>
      
      
      <List spacing={3} textAlign="start" w={"40%"}>
      </List>
      
      <VStack align={"left"} w={"40%"}>
      <Heading size={'xl'}>{price.cost + ",-"}</Heading>
        <Text>{price.terms}</Text>
      </VStack>
      <Stack>
        <Link href='https://www.matchi.se/facilities/mossklatresenter?fbclid=IwAR1NbykGfk1R1ypDFyio_CM4yLmwdZSxK8EaV5aZVmzgoXmkiO_JjBpneRo' isExternal>
          <Button
            size="md">
            {"Kjøp"}
          </Button>
        </Link>
      </Stack>
    </Stack>
  );
};

interface Props {
  prices: Price[]
}

export default function PriceCardList(props: Props) {
  return (
    <Box py={6} px={5}>
      <Stack spacing={4} width={'100%'} direction={'column'}>
        <Stack
          p={5}
          alignItems={'center'}
          justifyContent={{
            base: 'flex-start',
            md: 'space-around',
          }}
          direction={{
            base: 'column',
            md: 'row',
          }}>
          <Stack
            width={{
              base: '100%',
              md: '40%',
            }}
            textAlign={'center'}>
            <Heading size={'lg'}>
            Vi har flere typer medlemskap som dekker <Text as='u' color="#FF980C"> dine behov</Text>
            </Heading>
          </Stack>
          <Stack
            width={{
              base: '100%',
              md: '60%',
            }}>
            <Text textAlign={'center'}>
              Obs: Vi administrerer våre medlemskap gjennom vår samarbeidspartner MATCHI. Når du trykker på "Kjøp" vil du bli navigert til MATCHI sine sider.
            </Text>
          </Stack>
        </Stack>
        {props.prices.map((price: Price, i: number) => {
          
          let change = i === 0 || (i > 0 && props.prices[i].type.id !== props.prices[i -1].type.id)
          return (
            <>
              {change ? <Heading paddingTop={20}>{price.type.name}</Heading> : <></>}
              <Divider />
              {PackageTier(price)}
            </>
          )
        })}
      </Stack>
    </Box>
  );
};