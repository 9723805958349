import * as React from "react"
import Footer from "../components/Widgets/Footer";
import LayoverText from "../components/Widgets/LayoverText";
import SubmitFormComponent from "../components/Widgets/SubmitFormComponent";
import { SignForm, submitSign } from "../network/network";
import { useEffect } from "react";

function SafetyPage() {
  
  async function sign(signForm: SignForm) {
    const res = await submitSign(signForm)
    return res
  }
  
  useEffect(() => {
    const href = window.location.href
    if (href.includes("#")) {
      const id = `${href.substring(href.indexOf("#") + 1)}`
      const anchor = document.getElementById(id)
      if(anchor){
          anchor.scrollIntoView({ behavior: "smooth" })
      }
    }
  }, [])

  return (
    <>
      <LayoverText shadow={false} offset={false} header="Sikkerhetsregler"content={[
        "• Øv på fallteknikk: prøv å lande med beina først. Land med bøyde bein, sett baken på madrassen. Ikke ta deg for med armene, da utstrakte ledd er mer utsatt for skader.",
        "• Sjekk at fallsonen under deg er klar.",
        "• Klatre ned og benytt deg av tilgjengelige nedklatringstak, dette er de store grå takene som er på alle veggene.",
        "• Unngå å ha ting i lommene.",
        "• Pass på å aldri gå under noen når de buldrer, slik at du unngår å bli falt på.",
        "• Det skal ikke ligge personlige gjenstander på mattene, slik som drikkeflasker og mobiler."
      ]} />
      
      <LayoverText id="terms" shadow={false} offset={false} header="Ansvarserklæring" subHeader="Må signeres" content={[
        "• Jeg er innforstått med at buldring innebærer risiko som utøves på eget ansvar.",
        "• Jeg skal gjøre mitt beste for å ivareta egen og andres sikkerhet i buldreanlegget og jeg har lest gjennom sikkerhetsreglene for å unngå skade.",
        "• Jeg er klar over at det er farlig å oppholde seg i fallsonen til andre buldrere.",
        "• Jeg er pliktig til å varsle personalet dersom jeg oppdager feil på anlegget, eller dersom andres atferd utgjør en risiko.",
        "• Jeg må innrette meg etter instrukser fra personalet der jeg klatrer, dersom personalet mener jeg utgjør en risiko, har personalet rett til å bortvise personer etter eget skjønn.",
        "• Ulykkesforsikring som inkluderer klatring må besørges av deg selv.",
        "• Hverken eiere av buldrehallen eller personalet kan holdes ansvarlig for tap eller skade i forbindelse med buldringen.",
        "",
        "",
        "Mindreårig",
        "• Barn fra 13-17 år kan buldre alene, men foresatte må signere ansvarserklæring, og både barn og voksen må være innforstått med ansvarserklæringen og sikkerhetsreglene. ",
        "• Barn under 13 år må være under oppsyn av foresatte hele tiden, og den foresatte har fullt ansvar for sikkerheten/kompetansen til barnet.",
        "• Som foresatt er jeg innforstått med at fallende buldrere kan utgjøre en fare for barnet. "
       ]} />
       <SubmitFormComponent signCallback={sign}></SubmitFormComponent>
      <Footer />
    </>

  )
}

export default SafetyPage;