import amundImage from "../assets/employee/amund.jpg"
import aneImage from "../assets/employee/ane.png"
import henryImage from "../assets/employee/henry.jpg"
import rasmusImage from "../assets/employee/rasmus.jpg"
import gudrunImage from "../assets/employee/gudrun.jpg"

interface RandomEmployee {
  src: any
  name: string
}

export function getRandomEmployee(specific?: "Amund" |"Ane" |"Henry" | "Rasmus" | "Gudrun" ): RandomEmployee {
  let random = Math.floor(Math.random() * 5);
  switch (specific) {
    case "Amund":
      random = 0
      break
    case "Ane":
      random = 1
      break
    case "Henry":
      random = 2
      break
    case "Rasmus":
      random = 3
      break
    case "Gudrun":
      random = 4
      break
    default:
      break;
  }
  
  if(random === 0) {
    return {src: amundImage, name: "Amund Fevang"}
  } else if (random === 1) {
    return {src: aneImage, name: "Ane Høydal Rognsaa"}
  } else if (random === 2) {
    return {src: henryImage, name: "Henry A. Isegran"}
  } else if (random === 3) {
    return {src: rasmusImage, name: "Rasmus Andreas Isegran"}
  } else if (random === 4) {
    return {src: gudrunImage, name: "Gudrun Reitan"}
  }
  return {src: amundImage, name: "Amund Fevang"} 
}