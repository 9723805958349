


export function getTimeInterval(secondsSinceEpoch: number) {
    let date = new Date(secondsSinceEpoch * 1000); // The 0 there is the key, which sets the date to the epoch
    let nextH: number = -1;
    if(date.getHours() + 1 === 24) {
      nextH = 0;
    } else {
      nextH = date.getHours() + 1;
    }
    
    let hour: string = date.getHours().toString().length === 2 ? date.getHours().toString() : "0" + date.getHours().toString();
    let nextHour: string = nextH.toString().length === 2 ? nextH.toString() : "0" + nextH.toString();
    return hour.toString() + "-" + nextHour.toString();
}

function padTo2Digits(num: Number) {
  return num.toString().padStart(2, '0');
}

export function padTo4Digits(num: Number) {
  return num.toString().padStart(4, '0');
}

export function formatDate(dateInput: string, includeTime: boolean = false) {
  var date = new Date(Date.parse(dateInput));
  const dateString = [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join('/')
  
  const timeString = [
    padTo2Digits(date.getHours()),
    padTo2Digits(date.getMinutes()),
  ].join(':')
  return dateString + (includeTime ? " " + timeString : "");
}

export function formatISODate(dateInput: string) {
  var date = new Date(Date.parse(dateInput));
  return date.getUTCFullYear() + "-" + padTo2Digits(date.getMonth() + 1) + "-" + padTo2Digits(date.getDate())
}

export function toISOString(dateString: string) {
  const date = new Date(dateString);
  return date.toISOString()
}

export function formatDateBetweenTwoDates(fromDateInput: string, toDateInput: string) {
  const fromDate = new Date(Date.parse(fromDateInput));
  const toDate = new Date(Date.parse(toDateInput));
  
  const fromDateString = [
    padTo2Digits(fromDate.getDate()),
    padTo2Digits(fromDate.getMonth() + 1),
  ].join('/')
  
  const toDateString = [
    padTo2Digits(toDate.getDate()),
    padTo2Digits(toDate.getMonth() + 1),
  ].join('/')
  
  const timeString = [
    padTo2Digits(fromDate.getHours()) + ":" + padTo2Digits(fromDate.getMinutes()),
    padTo2Digits(toDate.getHours())+ ":" + padTo2Digits(toDate.getMinutes()),
  ].join('-')
  
  if(fromDate.getFullYear() === toDate.getFullYear() && fromDate.getMonth() === toDate.getMonth() && fromDate.getDate() === toDate.getDate()) {
    // Event starts and ends on same day
    return fromDateString + " kl. " + timeString
  } else {
    return fromDateString + " kl. " + padTo2Digits(fromDate.getHours()) + ":" + padTo2Digits(fromDate.getMinutes()) + " - " + toDateString + " kl. " + padTo2Digits(toDate.getHours())+ ":" + padTo2Digits(toDate.getMinutes())
  }
}