import LayoverText from "../components/Widgets/LayoverText";
import Footer from "../components/Widgets/Footer";
import { getPrices, Price } from "../network/network";
import React, { useEffect, useState } from "react";
import { Box, Center } from "@chakra-ui/react";
import PriceCardList from "../components/Widgets/Shared/PriceCard";


function PricesPage() {
  const [prices, setPrices] = useState<Price[]>([]);
  
  useEffect(() => {
    async function getPricesFromDatabase() {
      const prices = await getPrices()
      setPrices(prices);
    };
    getPricesFromDatabase()
  }, []);
  
  return (
    <>
      <Center>
      <Box w={['100%', '100%', '90%', '1000px']} paddingBottom={20}>
        <LayoverText paddingTop={20} shadow={false} header={"Priser"} content={[]} />
        <Center>
        <PriceCardList prices={prices}></PriceCardList>
        </Center>
      </Box>
      </Center>
      
      <Footer/>
    </>
  )
}

export default PricesPage;