export interface Price {
  id: number
  uuid: string
  isActive: boolean
  name: string
  description: string
  cost?: number
  terms: string
  type: PriceCategory
}

export interface PriceCategory {
  id: number
  name: string
}

export interface Inquiry {
  name: string
  email: string
  content: string
}

export interface SignForm {
  name: string
  email: string
  content: string
}

export interface Post {
  id: number
  uuid: string
  width: number
  height: number
  images: InstagramImage[]
  caption: string
  uploaded: string
  pinned: string
}

export interface InstagramImage {
  id: BigInt
  image: string
}

export interface Notification {
  uuid: string
  title: string
  subtitle: string
  category: NotificationCategory
  fromDate: string
  toDate: string
}

export interface NotificationCategory {
  name: string
  categoryColor: "green" | "blue" | "red" | "yellow"
}

const baseUrl: string = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? "http://192.168.0.48:7104" : window.location.origin;

// ----------------- Price -----------------
export async function getPrices(): Promise<Price[]> {
    const res = await fetch(baseUrl + '/api/Price')
    const res_1 = await res.json()
    return res_1 as Price[]
}

// ----------------- Inquiry -----------------
export async function submitInquiry(inquiry: Inquiry): Promise<boolean> {
  const res = await fetch(baseUrl + '/api/Inquiry', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(inquiry)
  })
  if(res.status !== 200) {
    return false;
  }
  return true;
}

// ----------------- Posts -----------------
export async function getPosts(): Promise<Post[]> {
  const res = await fetch(baseUrl + '/api/Post', {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  })
  if(res.status !== 200) {
    return []
  }
  const res_1 = await res.json()
  return res_1 as Post[]
}

// ----------------- Images -----------------
export async function getImage(id: number): Promise<InstagramImage | null> {
  const res = await fetch(baseUrl + '/api/Image/' + id, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  })
  if(res.status !== 200) {
    return null;
  }
  const res_1 = await res.json()
  return {id: res_1["id"], image: "data:image/png;base64," + res_1["image"]} as InstagramImage
}

// ----------------- Sign -----------------
export async function submitSign(signForm: SignForm): Promise<boolean> {
  const res = await fetch(baseUrl + '/api/Sign', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(signForm)
  })
  if(res.status !== 200) {
    return false;
  }
  return true;
}


// ----------------- Events -----------------
export async function getNotifications(): Promise<Notification[]> {
  const res = await fetch(baseUrl + '/api/Notification', {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  })
  if(res.status !== 200) {
    return []
  }
  const res_1 = await res.json()
  return res_1 as Notification[]
}