import * as React from "react"
import Footer from "../components/Widgets/Footer";
import LayoverText from "../components/Widgets/LayoverText";

function EventsPage() {
  return (
    <>
      <LayoverText shadow={false} offset={false} subHeader="Bli med på Buldrekarusellen hos Moss klatresenter!" content={[
        "Er du klar for å utfordre deg selv, ha det gøy og bli en del av Moss klatresenters klatrefellesskap? Da er Buldrekarusellen det perfekte arrangementet for deg!",
        "• Lørdag 28. oktober",
        "• Lørdag 25. november",
        "• Fredag 22. desember",
        "",
        "Grunner til at du bør delta:",
        "{$bold_Vær-med-på-en-spennende-klatrekarusell:}",
        "Buldrekarusellen er en unik mulighet til å delta på tre sosiale arrangementer som kombinerer buldrekonkurranser, faglig påfyll og sosialt samvær. Det er den perfekte anledningen til å forbedre dine klatreferdigheter, lære noe nytt og ha det gøy sammen med likesinnede klatrere. Arrangementene er tilpasset barn og voksne.",
        "",
        "{$bold_Lavterskel-og-tilpasset-for-alle-nivåer:}",
        "Vi har deltakere på alle ferdighetsnivåer, så uansett om du er nybegynner eller erfaren klatrer, har du en plass i Buldrekarusellen. Velg din klasse (hvit/grønn, grønn/blå, blå/gul, gul/rød, rød/svart) basert på dine egne ferdigheter. Det er ingen press - bare utfordringer som passer akkurat for deg.",
        "",
        "{$bold_Spenning-og-konkurranse:}",
        "Konkurransen handler om å klare flest mulige buldre i din egen klasse. Du får ditt eget scorecard og krysser av for hver bulder du klarer. Hvem blir klassevinneren basert på poengsum? Det kan bli deg! Vi har dyno-konkurranser som utfordrer dine klatreferdigheter på en morsom måte!",
        "",
        "{$bold_Mer-enn-bare-konkurranser:}",
        "Buldrekarusellen har mer å by på enn bare klatrekonkurranser. Dagene vil ha spennende foredrag og faglig påfyll som gir deg nyttig innsikt. Og selvfølgelig, ingen klatrearrangement er komplett uten pizza og god stemning!",
        "",
        "{$bold_Superfinale-med-høydepunkter:}",
        "Karusellen fungerer både som enkeltstående og sammenhengende arrangementer. Det vil kåres vinnere for hver konkurranse, samtidig som poengene akkumuleres etter hvert arrangement. Dersom du er blant de med høyest poengsum etter de to første arrangementene, har du sjansen til å delta i en spennende superfinale under det tredje arrangementet den 22. desember!",
        "",
        "{$bold_Ingen-påmelding-nødvendig:}",
        "Du trenger ikke å melde deg på på forhånd. Det er bare å møte opp og være med på moroa! For at vi skal ha mulighet til å avklare hvor mange som deltar oppfordrer vi deg likevel til å trykke «delta» på vårt {$anchor_facebook-arrangement_https://www.facebook.com/events/805448964734269?paipv=0&eav=AfZQj4CvDP8_f3EnONoBPU-IWUJ1TJSwFePraa_wE5Vh_i-ab5pMNZ65Jm-kGQgieGg&_rdr} . Så, hva venter du på? Sett datoen i kalenderen og bli med på Buldrekarusellen hos Moss klatresenter. Vi gleder oss til å se deg der!",
        "",
        "{$bold_Pris:}",
        "Vanlig inngang + 50 kr for scorekort"
      ]} />
      <Footer />
    </>
  )
}

export default EventsPage;
