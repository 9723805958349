import { Center, Container, chakra, Heading, Text, Box, Link} from "@chakra-ui/react";
import React, {  } from "react";
import "../../index.css"
import {Link as ReactLink, NavigateFunction, useNavigate } from "react-router-dom";


interface Props {
  id?: string
  header?: string
  subHeader?: string
  content: string[]
  shadow?: boolean
  offset?: boolean
  transparent?: boolean
  paddingTop?: number
}

const returnTextFields = (content: string[], navigate: NavigateFunction) => {
  return content.map((text: any, i: number) => {
    
    let textArray: any = text.split(" ");
    for (let i = 0; i < textArray.length; i++) {
      if(textArray[i].startsWith("{$link") && textArray[i].endsWith("}")) {
        const text = textArray[i].split("_")[1]
        const href = textArray[i].split("_")[2].replace("}", "")
        textArray[i] = <Link color='#FF980C' href={href} as={ReactLink} to={href} onClick={(event: any) => {event.preventDefault(); navigate(href)}}>{text}</Link>

      } else if(textArray[i].startsWith("{$anchor") && textArray[i].endsWith("}")) {
        const text = textArray[i].split("_")[1]
        const href = textArray[i].split("_")[2].replace("}", "")
        textArray[i] = <Link color='#FF980C' target='_blank' rel='noopener noreferrer' href={href}>{text}</Link>
      } else if (textArray[i].startsWith("{$bold") && textArray[i].endsWith("}")) {
        const text = textArray[i].split("_")[1].replaceAll("-", " ").replace("}", "")
        textArray[i] = <span style={{fontWeight: 'bold'}}>{text + " "}</span>
      } else {
        textArray[i] = <span>{textArray[i] + " "}</span>
      }
    }
    
    return (
    <Text key={text + i.toString()} paddingTop={"20px"} fontSize={{ base: '16px', sm: "18px" }}>{textArray}</Text>
    )
  })
}

export function LayoverText(props: Props) {
  const navigate = useNavigate()
  return (
    <Box id={props.id}  w='100%' paddingBottom={"50px"} paddingTop={props.paddingTop}>
      <Center>
        <Container bg={props.transparent ? 'transparent' : 'white'} position={"relative"} top={props.offset ? ["-50px"] : []} className={props.shadow ? "rounded-corners" : ""} width={["100%", "100%", "50%"]}>
          {props.header ?
            <Center>
              <chakra.h1
                fontSize={{
                  base: "3xl",
                  sm: "4xl",
                  md: "5xl",
                }}
                letterSpacing="tight"
                lineHeight="short"
                fontWeight="extrabold"
                color="gray.900"
                _dark={{
                  color: "white",
                }}
              >
                <chakra.span
                  display={{
                    base: "block",
                    xl: "inline",
                  }}
                >
                  {props.header}
                </chakra.span>
              </chakra.h1>
            </Center> : <></>}
          {props.subHeader ?
            <Center>
              <Heading as='h3' size='lg'>{props.subHeader}</Heading>
            </Center> : <></>}
          <Box p={4} maxWidth="60ch">
            {returnTextFields(props.content, navigate)}
          </Box>
        </Container>
      </Center>
    </Box>
  )
}

export default LayoverText;
