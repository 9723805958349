import { Center, Image } from "@chakra-ui/react";
import * as React from "react"
import LayoverText from "../components/Widgets/LayoverText";
import Footer from "../components/Widgets/Footer";
import mobileBookingOverview from "../assets/Mobil-booking-1.png"
import mobileBookingReciept from "../assets/Mobil-booking-2.png"


function GuidePage() {
  
  return (
    <>
      <LayoverText shadow={false} offset={false} header="Booking og inngang" subHeader="Booking på nett" content={[
        "1. Naviger til {$link_Priser_/prices} -fanen.",
        "2. Klikk «Kjøp» på produktet du ønsker å kjøpe. Du vil da bli navigert til MATCHi.se.",
        "3. Dersom du vil kjøpe klippekort eller halvtårskort så finner du de under «Offers (discount cards, etc.)». Og drop-in finnes under «Activities».",
        "Dersom du kjøper klippekort eller halvtårskort må du fortsatt booke tid under «Activities», men prisen belastes da på klippekortet/halvtåraskortet ditt.",
        "4. Om du ikke har en MATCHi-konto må du opprette dette! Det er her du vil finne produktet igjen senere.",
        "5. Klikk på aktiviteten du vil booke. Husk å velg riktig dag.",
        "6. Velg så betalingsmåte (kort eller klippekort), godta vilkår og book aktiviteten.",
        "7. Kvittering vil bli sendt til din epost, sammen med en kode du kan bruke på kodelåsen ved inngangsdøren (Koden vil være aktiv i tidsrommet aktiviteten varer)."
      ]} />
      
      <LayoverText shadow={false} offset={false} subHeader="Booking gjennom mobilapp" content={[
        "1. Last ned MATCHi-appen og lag deg en bruker/konto. Dersom du har en bruker fra før av (ved f.eks booking på nett) kan denne brukes.",
        "2. Søk opp Moss Klatresenter og klikk inn på siden.",
        "3. Dato ser du øverst og under «Aktiviteter» kan du booke din inngang.",
      ]} />
      <Center>
        <Image className="rounded-borders" src={mobileBookingOverview} w={['50%', '50%', '40%', '260px']} marginTop={["-10px", "-10px", "40px"]} />
      </Center>
       <LayoverText shadow={false} offset={false} content={[
        "4. Velg riktig aktivitet: I dette tilfellet Drop-in Voksen (Kveld/Helg/Helligdag).",
        "5. Klikk «Continue» eller «Fortsett».",
        "6. Deretter kan du velge betalingsmåte (kort, klippekort eller halvårskort), godkjenne vilkår og klikke på «Book» nederst på siden.",
      ]} />
      <Center>
        <Image className="rounded-borders" src={mobileBookingReciept} w={['50%', '50%', '40%', '260px']} marginTop={["-10px", "-10px", "40px"]} />
      </Center>
      <LayoverText shadow={false} offset={false} content={[
        "7. Nå er inngang booket og du vil få en reservasjon i appen der du kan se inngangskoden til inngangsdøren."
      ]} />
      <Footer />
    </>
  )
}

export default GuidePage;