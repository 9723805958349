import React, { useEffect, useState } from 'react';
import {
  Box,
  Link,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  Container,
  SkeletonText,
  SkeletonCircle,
  Spinner,
  Center,
} from '@chakra-ui/react';
import { getPosts, Post } from '../../network/network';
import ImageCaruselPosts from './Shared/ImageCaruselPost';
import { getRandomEmployee } from '../../network/EmployeeProvider';

interface IBlogTags {
  tags: Array<string>;
  marginTop?: SpaceProps['marginTop'];
}

const BlogTags: React.FC<IBlogTags> = (props) => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

interface BlogAuthorProps {
  date: Date;
  name: string;
}

export const BlogAuthor: React.FC<BlogAuthorProps> = (props) => {
  const randomEmpoyee = getRandomEmployee()
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src={randomEmpoyee.src}
        alt={`Avatar of ${randomEmpoyee.name}`}
      />
      <Text fontWeight="medium">{randomEmpoyee.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const BlogComponent = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  
  useEffect(() => {
    async function getPostsFromDatabase() {
      const posts = await getPosts()
      setPosts(posts);
    };
    getPostsFromDatabase()
  }, []);
  
  const FeaturedPostOneImage = () => {
    return (<Image
      borderRadius="lg"
      src={posts[0].images[0].image ? "data:image/png;base64," + posts[0].images[0].image : ""}
      alt="some good alt text"
      objectFit="contain"
      transition="0.3s ease-in-out"
      _hover={{
        transform: 'scale(1.05)',
      }}
    />)
  }
  
  interface OlderPostOneImageProps {
    post: Post
  }
  const OlderPostOneImage = (props: OlderPostOneImageProps) => {
    return (<Image
      transform="scale(1.0)"
      src={props.post.images[0].image ? "data:image/png;base64," + props.post.images[0].image : ""}
      alt="some text"
      objectFit="contain"
      width="100%"
      transition="0.3s ease-in-out"
      _hover={{
        transform: 'scale(1.05)',
      }}
    />)
  }
  
  function createFeaturedPost(){
    if(posts.length === 0){
      return (<Box key={"createFeaturedPost"}
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between">
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center">
          <Box
            width={{ base: '100%', sm: '85%' }}
            zIndex="2"
            marginLeft={{ base: '0', sm: '5%' }}
            marginTop="5%">
            <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
            <Center><Spinner size='lg' /></Center>
            </Link>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box
              bgGradient={'radial(orange.600 1px, transparent 1px)'}
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}>
          <BlogTags tags={['Laster']} />
          <Box padding='6' bg='white'>
            <SkeletonText mt='4' noOfLines={4} spacing='4' />
            <Box paddingTop={"6px"}></Box>
            <SkeletonCircle size='10' />
          </Box>
        </Box>
      </Box>)
    }
    
    return(<Box
      marginTop={{ base: '1', sm: '5' }}
      display="flex"
      flexDirection={{ base: 'column', sm: 'row' }}
      justifyContent="space-between">
      <Box
        display="flex"
        flex="1"
        marginRight="3"
        position="relative"
        alignItems="center">
        <Box
          width={{ base: '100%', sm: '85%' }}
          zIndex="2"
          marginLeft={{ base: '0', sm: '5%' }}
          marginTop="5%">
          {posts[0].images.length > 1 ? <ImageCaruselPosts images={posts[0].images}></ImageCaruselPosts> : <FeaturedPostOneImage></FeaturedPostOneImage>}
        </Box>
        <Box zIndex="1" width="100%" position="absolute" height="100%">
          <Box
            bgGradient={'radial(orange.600 1px, transparent 1px)'}
            backgroundSize="20px 20px"
            opacity="0.4"
            height="100%"
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flex="1"
        flexDirection="column"
        justifyContent="center"
        marginTop={{ base: '3', sm: '0' }}>
        <BlogTags tags={posts[0].pinned ? ["Festet innlegg"] : ['Siste innlegg']} />
        <Text
          as="p"
          marginTop="2"
          color={'gray.700'}
          fontSize="lg">
          {posts[0].caption}
        </Text>
        <BlogAuthor name="Moss Klatresenter" date={new Date(posts[0].uploaded)} />
      </Box>
    </Box>)
  }
  
  function createOlderPosts(){
    if(posts.length === 0){
      let loadingComponents = []
      for(let i = 0; i < loadingComponents.length; i++){
        loadingComponents.push(<WrapItem key={i.toString() + " createOlderPosts"} width={{ base: '100%', sm: '45%', md: '45%', lg: '30%' }}>
        <Box w="100%">
          <Box borderRadius="lg" overflow="hidden">
            <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
              <Image
                transform="scale(1.0)"
                src={""}
                alt="some text"
                objectFit="contain"
                width="100%"
                transition="0.3s ease-in-out"
                _hover={{
                  transform: 'scale(1.05)',
                }}
              />
            </Link>
          </Box>
          <Box padding='6' bg='white'>
            <SkeletonText mt='4' noOfLines={4} spacing='4' />
            <Box paddingTop={"6px"}></Box>
            <SkeletonCircle size='10' />
          </Box>
        </Box>
      </WrapItem>)
      }
      return loadingComponents
    }
    
    return(posts.map((element: Post, i: number) => {
      if(i === 0) return (<></>)
      
      return (<WrapItem key={i.toString() + " createOlderPosts exists"} width={{ base: '100%', sm: '75%', md: '45%', lg: '30%' }}>
      <Box w="100%">
        <Box borderRadius="lg" overflow="hidden">
          {element.images.length > 1 ? <ImageCaruselPosts images={element.images}></ImageCaruselPosts> : <OlderPostOneImage post={element}></OlderPostOneImage>}
        </Box>
        <Text as="p" fontSize="md" marginTop="2">
          {element.caption}
        </Text>
        <BlogAuthor
          name="Moss Klatresenter"
          date={new Date(element.uploaded)}
        />
      </Box>
    </WrapItem>)
    }))
  }
  
  return (
    <Container maxW={'7xl'} p="12">
      {createFeaturedPost()}
      <Divider marginTop="5" />
      <Wrap spacing="30px" marginTop="5" justify='center'>
        {createOlderPosts()}
      </Wrap>
    </Container>
  );
};

export default BlogComponent;
