import { Center, Image } from "@chakra-ui/react";
import * as React from "react"
import Footer from "../components/Widgets/Footer";
import LayoverText from "../components/Widgets/LayoverText";

import climberCourse from "../assets/Oversikt-vegg4.jpg"

function CoursesPage() {

  return (
    <>
      <Center>
        <Image className="rounded-borders" src={climberCourse} w={['100%', '100%', '90%', '1000px']} marginTop={["-10px", "-10px", "40px"]} />
      </Center>
      <LayoverText shadow={true} offset={true} header="Kurs" subHeader="Nybegynnerkurs i buldring" content={[
        "Har du alltid hatt lyst til å prøve buldring, men aldri funnet den perfekte muligheten? Nå er sjansen her! Vårt introduksjonskurs gir deg inngangsverdiene du trenger for en super start på din buldrekarriere!",
        "",
        "{$bold_Kursdetaljer}",
        "{$bold_Hva:} Introduksjonskurs",
        "{$bold_Varighet:} To økter, hver på 1,5 timer",
        "{$bold_Når:}",
        "• Alternativ 1: 27. september kl 18:00 – 19:30 & 04. oktober kl 18:00 - 19:30",
        "• Alternativ 2: 14. oktober kl 13:00 – 14:30 & 19. oktober kl 18:00 – 19:30",
        "{$bold_Pris:} 499 kr",
        "{$bold_Påmelding:} Send navn, e-post, telefonnummer og ønsket dato til kontakt@mossklatresenter.no eller via vårt {$link_kontaktskjema._/about#contact}",
        "",
        "PS: Du MÅ ikke delta på et nybegynnerkurs for å buldre hos oss, men det gir deg trygghet og forutsetninger for å mestre raskere.",
      ]} />
      <LayoverText shadow={false} offset={true} subHeader="Treningsgrupper for barn og unge" content={[
        "Fra og med mandag 21.08 starter vi opp med faste treningsgrupper for barn og unge. Kurset varer frem til tirsdag 19.12.",
        "Hver gruppe består av 6-8 barn/unge som vil lære seg buldring og finne seg klatrevenner i Moss. Gruppene er for alle nivåer, enten man ønsker intro til buldring, målrettet trening eller om man bare vil ha det gøy. Det viktigste for oss er at barna trives, at de kjenner på mestring og at de kan utfolde seg i veggen!",
        "",
        "{$bold_Når:}",
        "• Mandager: kl. 17:30 - 18:30 (12 - 15 år)",
        "• Tirsdager: kl. 17:30 - 18:30 (8 - 11 år)",
        "",
        "{$bold_Pris:} 2290 kr",
        "* Prisen inkluderer inngang og lån av sko.",
        "",
        "{$bold_Påmelding:} Ønske om plass på kurset sendes til kontakt@mossklatresenter.no eller via vårt {$link_kontaktskjema._/about#contact}",
        "",
        "Det er begrenset antall plasser, så det er «førstemann til mølla» som gjelder. Når bekreftet plass er påmelding bindende.",
      ]} />
      
      <Footer />
    </>

  )
}

export default CoursesPage;
