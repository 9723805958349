import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    if(hash !== ""){
      console.log(pathname + " : " + hash)
      const anchor = document.getElementById(hash.replace("#",""))
      console.log(anchor)
      if(anchor){
          anchor.scrollIntoView({ behavior: "auto" })
          return
      }
    }
    
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto", // Optional if you want to skip the scrolling animation
    });
  }, [pathname, hash]);

  return null;
}