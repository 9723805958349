import * as React from "react"
import HomePage from "./Page/HomePage"
import { Routes, Route } from "react-router-dom";
import AboutPage from "./Page/AboutPage";
import Navbar from "./components/Widgets/Navbar";
import PricesPage from "./Page/PricesPage";
import { Box } from "@chakra-ui/react";
import WhatIsPage from "./Page/WhatIsPage";
import CoursesPage from "./Page/Courses";
import OpeningHoursPage from "./Page/OpeningHours";
import SafetyPage from "./Page/SafetyPage";
import ScrollToTop from "./network/helper";
import GuidePage from "./Page/GuidePage";
import ReturnPolicyPage from "./Page/ReturnPolicyPage";
import EventsPage from "./Page/EventsPage";


export const App = () => {
  const routes = [
    {label: "Forside", href: "/"},
    {label: "Hva er buldring?", href: "/bouldering"},
    {label: "Priser", href: "/prices"},
    {label: "Booking og inngang", href: "/guide"},
    {label: "Kurs", href: "/courses"},
    {label: "Events", href: "/events"},
    {label: "Åpningstider", href: "/opening-hours"},
    {label: "Sikkerhet", href: "/safety"},
    {label: "Om oss", href: "/about"},
    {label: "Kontakt", href: "/about#contact"},
  ]

  return(
    <div>
      <Navbar routes={routes}></Navbar>
      <ScrollToTop />
      <Box w='100%' paddingTop={"65px"}>
      <Routes>
        <Route path={"/"} element={<HomePage/>} />
        <Route path={"/bouldering"} element={<WhatIsPage/>} />
        <Route path={"/prices"} element={<PricesPage />} />
        <Route path={"/guide"} element={<GuidePage />} />
        <Route path={"/courses"} element={<CoursesPage/>} />
        <Route path={"/events"} element={<EventsPage/>} />
        <Route path={"/opening-hours"} element={<OpeningHoursPage/>} />
        <Route path={"/safety"} element={<SafetyPage/>} />
        <Route path={"/about"} element={<AboutPage/>} />
        <Route path={"/return-policy"} element={<ReturnPolicyPage/>} />
        <Route path="*" element={<HomePage />} />
        </Routes>
      </Box>
    </div>
)}





