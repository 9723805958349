import {
  Box,
  Button,
  Center,
  chakra,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Textarea,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

import {
  FiUser,
  FiMail,
} from 'react-icons/fi';
import { SignForm } from '../../network/network';

interface Props {
  signCallback: (signForm: SignForm) => Promise<boolean>
}

export default function SubmitFormComponent(props: Props) {
  const [signForm, setSignForm] = React.useState<SignForm>({} as SignForm);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [accept, setAccept] = React.useState<boolean>(false);
  
  const [nameValid, setNameValid] = React.useState<boolean>(true);
  const [emailValid, setEmailValid] = React.useState<boolean>(true);
  const [contentValid, setContentValid] = React.useState<boolean>(true);
  const [acceptedValid, setAcceptedValid] = React.useState<boolean>(true);

  
  const toast = useToast()
  function showToast(title: string, status: "info" | "warning" | "success" | "error" | undefined = "success"){
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  }
  
  async function submitInquiry(){
    toast.closeAll()
    
    const nameValidRes = validateName(signForm.name)
    await setNameValid(nameValidRes)
    
    const emailValidRes = validateEmail(signForm.email)
    await setEmailValid(emailValidRes)
    
    const contentValidRes = validateContent()
    await setContentValid(contentValidRes)
    
    const acceptValidRes = validateAccepted(accept)
    await setAcceptedValid(acceptValidRes)
    
    
    if(
      nameValidRes &&
      emailValidRes &&
      contentValidRes &&
      acceptValidRes) {
      setLoading(true)
      const res = await props.signCallback(signForm)
      if(res) {
        setLoading(false)
        showToast("Ansvarserklæring er signert")
        setSignForm({} as SignForm)
        
      } else {
        setLoading(false)
        showToast("Noe gikk galt" , "error")
      }
    }
  }
  
  function validateName(name: string): boolean {
    if(!name || name.trim() === "") {
      showToast('Navn er ikke fylt ut', 'error')
      return false;
    }
    const expression: RegExp = /^[a-zA-ZæøåÆØÅ\s\-]*$/i;
    const result: boolean = expression.test(name); // true
    if(!result || name.length > 50) {
      showToast('Navn er ikke gyldig', 'error')
      return false;
    }
    return true;
  }
  
  function validateEmail(email: string): boolean {
    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const result: boolean = expression.test(email); // true
    if(!result){
      showToast('Epost er ikke gyldig', 'error')
      setEmailValid(false)
      return false;
    }
    setEmailValid(true)
    return true;
  }
  
  function validateContent(): boolean {
    return true;
  }
  
  function validateAccepted(accept: boolean): boolean {
    return accept;
  }

  return (
    <Center>
      <Flex
      w={['70%', '70%', '70%', '800px']}
      align="center"
      justify="center"
      id="contact"
      paddingBottom={"50px"}>
      <Box
        bg={useColorModeValue('white', 'gray.700')}
        borderRadius="lg"
        p={12}
        color={useColorModeValue('gray.700', 'whiteAlpha.900')}
        shadow="base">
        <VStack spacing={5}>
        <Heading
              fontSize={{
                base: '4xl',
                md: '5xl',
              }}>
              Signer samtykke
            </Heading>
            <Text>Vennligst fyll ut dette skjemaet for å samtykke til ansvarserklæringen </Text>
          <FormControl isRequired>
            <FormLabel>Navn</FormLabel>

            <InputGroup>
              <InputLeftElement children={<FiUser />} />
              <Input 
                isInvalid = {!nameValid}
                errorBorderColor='red.300'
                type="text" 
                name="name" 
                placeholder="" 
                value={signForm.name ? signForm.name : ""}
                onChange={(event) => {
                  setSignForm({...signForm, name: event.target.value})
                }}
                />
            </InputGroup>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Epost</FormLabel>

            <InputGroup>
              <InputLeftElement children={<FiMail />} />
              <Input
                isInvalid = {!emailValid}
                errorBorderColor='red.300'
                type="email"
                name="email"
                placeholder=""
                value={signForm.email ? signForm.email : ""}
                onChange={(event) => {
                  setSignForm({...signForm, email: event.target.value})
                }}
              />
            </InputGroup>
          </FormControl>

          <FormControl>
            <FormLabel>Navn på barn (hvis relevant)</FormLabel>

            <Textarea
              isInvalid = {!contentValid}
              errorBorderColor='red.300'
              name="message"
              placeholder=""
              rows={2}
              resize="none"
              value={signForm.content ? signForm.content : ""}
              onChange={(event) => {
                setSignForm({...signForm, content: event.target.value})
              }}
            />
          </FormControl>
          
          <FormControl>
            <FormLabel></FormLabel>

            <CheckboxGroup >
            <Checkbox
              isRequired
              isInvalid = {!acceptedValid}
              isChecked={accept}
              maxW={"500px"}
              onChange={(event) => {
                setAccept(event.target.checked)
              }}
              >Jeg har lest og akseptert denne ansvarserklæringen, og forplikter meg til å overholde Moss Klatresenter AS sine regler. {<chakra.span
                display={{
                  base: "inline",
                }}
                color="red.500"
                _dark={{
                  color: "brand.400",
                }}>*</chakra.span>}</Checkbox>
            </CheckboxGroup>
          </FormControl>

          <Button
            isLoading = {loading}
            loadingText='Sender melding'
            colorScheme="blue"
            bg="blue.400"
            color="white"
            _hover={{
              bg: 'blue.500',
            }}
            width={"full"}
            onClick={() => {
              submitInquiry()
            }}>
            Send
          </Button>
        </VStack>
      </Box>
    </Flex>
    </Center>
  );
}