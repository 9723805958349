import * as React from "react"
import Footer from "../components/Widgets/Footer";
import LayoverText from "../components/Widgets/LayoverText";

function ReturnPolicyPage() {

  return (
    <>
      <LayoverText shadow={false} offset={false} header="Angrerett" subHeader="KJØP OG BRUK"content={[
        "• Gavekort/halvårskort/klippekort kan kjøpes på våre hjemmesider og på klatresenteret. Våre gavekort/klippekort er gyldig i 1 år fra kjøpsdato og kan ikke forlenges. Ubrukte gavekort/klippekort refunderes ikke. Halvårskort er gjeldende fra kjøpsdato.",
        "• Ved bruk av delbeløp på gavekortet vil restbeløpet bli værende og kunne brukes senere.",
        "• Hvis du mister eller blir frastjålet gavekortet vil eventuelt misbruk av gavekortet ikke erstattes. Du kan imidlertid hente frem elektroniske gavekortet igjen ved å kontakte oss.",
      ]} />
      
      <LayoverText id="terms" shadow={false} offset={false} subHeader="ANGREFRIST" content={[
        "• Om du er forbruker, gjelder 14 dagers angrerett ved kjøp av halvtårskort/klippekort/gavekort på nettsiden. Angrerett innebærer at du kan angre kjøpet av gavekortet uten å oppgi noen grunn. Ved godkjent bruk av angreretten, tilbakebetales kjøpet av halvtårskort/klippekort/gavekort. Angreretten må benyttes innen 14 dager etter datoen da halvtårskort/klippekort/gavekort ble kjøpt og brukte halvtårskort/klippekort/gavekort kan ikke angres. Gavekort kan ikke byttes mot kontanter, og kjøp av gavekort kan ikke gjøres om.",
        "• Dersom forbruker ønsker å benytte seg av angrerettet må mail sendes til kontakt@mossklatresenter.no.",
       ]} />
        
      <LayoverText id="terms" shadow={false} offset={false}subHeader="PERSONOPPLYSNINGSANSVARLIG OG KONTAKTOPPLYSNINGER" content={[
        "• Vi bruker MATCHi som teknisk partner for levering av våre tjenester for halvtårskort/klippekort/gavekort. De er vår databehandler, og personopplysninger og kontaktopplysninger som hentes inn er beskrevet i deres privacy Policy, ved kjøp av halvtårskort/klippekort/gavekort aksepterer du overføring av data til dem og bruk i henhold til dette.",
       ]} />
      <Footer />
    </>

  )
}

export default ReturnPolicyPage;