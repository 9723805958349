import { Flex, Box, HStack, Text, Image } from "@chakra-ui/react";
import { useState } from "react";
import { InstagramImage } from "../../../network/network";

interface Props {
  images: InstagramImage[]
}

export default function ImageCaruselPosts(props: Props) {
  const arrowStyles = {
    cursor: "pointer",
    top: "50%",
    w: "auto",
    mt: "-22px",
    p: "16px",
    color: "white",
    fontWeight: "bold",
    fontSize: "18px",
    transition: "0.6s ease",
    borderRadius: "0 3px 3px 0",
    _hover: {
      opacity: 0.8,
      bg: "black",
    },
  };
  
  
  const [currentSlide, setCurrentSlide] = useState(0);
  const slidesCount = props.images.length;

  const prevSlide = () => {
    setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
  };

  const nextSlide = () => {
    setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
  };

  const setSlide = (imageIndex: number) => {
    setCurrentSlide(imageIndex);
  };

  const carouselStyle = {
    transition: "all .5s",
    ml: `-${currentSlide * 100}%`,
  };
  
  
  return (
    <Flex
      w="full"
      alignItems="center"
      justifyContent="center"
    >
      <Flex w="full" overflow="hidden" pos="relative">
        <Flex w="full" {...carouselStyle} >
          {props.images.map((image, sid) => (
            <Box key={`slide-${sid}`} boxSize="full" flex="none">
              <Text
                color="white"
                fontSize="xs"
                p="8px 12px"
                pos="absolute"
                top="0"
              >
                {sid + 1} / {slidesCount}
              </Text>
              <HStack justify="center" pos="absolute" bottom="8px" w="full">
                {Array.from({
                  length: slidesCount,
                }).map((_, slide) => (
                  <Box
                    key={`dots-${slide}`}
                    cursor="pointer"
                    boxSize={["7px", null, "15px"]}
                    m="0 2px"
                    bg={currentSlide === slide ? "blackAlpha.800" : "blackAlpha.500"}
                    rounded="50%"
                    display="inline-block"
                    transition="background-color 0.6s ease"
                    _hover={{
                      bg: "blackAlpha.800",
                    }}
                    onClick={() => setSlide(slide)}
                  ></Box>
                ))}
              </HStack>
              <Image
                src={image?.image ? "data:image/png;base64," + image.image : ""}
                alt="carousel image"
                boxSize={"full"}
                width={"100%"}
                backgroundSize="cover"
                objectFit={"contain"}
                borderRadius="lg"
                transition="0.3s ease-in-out"
                _hover={{
                  transform: 'scale(1.05)',
                }}
              />
            </Box>
          ))}
          
        </Flex>
        <Text position={"absolute"} userSelect={"none"} {...arrowStyles} left="0" onClick={prevSlide}>
          &#10094;
        </Text>
        <Text position={"absolute"} userSelect={"none"} {...arrowStyles} right="0" onClick={nextSlide}>
          &#10095;
        </Text>
        
      </Flex>
    </Flex>
  );
};