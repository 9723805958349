import * as React from "react"
import Footer from "../components/Widgets/Footer";
import BlogComponent from "../components/Widgets/BlogComponent";
import { HeroHome } from "../components/Widgets/HeroHome";
import { Box } from "@chakra-ui/react";
import TimelineComponent from "../components/Widgets/TimelineComponent";

function HomePage() {
  return (
    <Box>
      <TimelineComponent></TimelineComponent>
      <HeroHome></HeroHome>
      <BlogComponent></BlogComponent>
      <Footer/>
    </Box>
    );
}

export default HomePage;
