import { Box, Center, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Notification, getNotifications } from "../../network/network";
import { formatDateBetweenTwoDates } from "../../network/util";
import MyBadge from "./Shared/MyBadge";

export default function TimelineComponent() {
  
  const [notifications, setNotifications] = useState<Notification[]>([]);
  
  useEffect(() => {
    
    async function getEventsFromDatabase() {
      const notifications = await getNotifications()
      setNotifications(notifications);
    };
    getEventsFromDatabase()
  }, []);
  
  if(notifications.length === 0) {
    return (<></>)
  }

  return (
    <Center>
      <Box w={['100%', '100%', '90%', '2000px']} mb={1} position={"relative"}>
        <Stack
          overflowX={"auto"}
          overflowY={"hidden"}
          p={3}
          py={1}
          justifyContent={{
            md: 'space-around',
          }}
          direction={{
            md: 'row',
          }}
          _after={{
            content: '""',
            position: 'absolute',
            zIndex: '1',
            top: '0',
            right: '0',
            bottom: '0px',
            pointerEvents: 'none',
            backgroundImage: 'linear-gradient(to right, rgba(255,255,255,0), white 85%)',
            width: '140px'
          }}
          alignItems={{ md: 'center' }}>
            {notifications.map((notification: Notification, _: number) => {
              return (
              <MyBadge 
                title={notification.title} 
                subtitle={notification.subtitle} 
                category={notification.category.name} 
                date={formatDateBetweenTwoDates(notification.fromDate, notification.toDate)}
                categoryColor={notification.category.categoryColor}/>
              )
            })}
        </Stack>
      </Box>
    </Center>
  );
}

/*
<MyBadge title="Omskruing" subtitle="Senteret er stengt i denne perioden" category="Vedlikehold" categoryColor="red" date="02/01 kl. 14-15"></MyBadge>
            <MyBadge title="Intro" subtitle="Undertittel" category="Kurs" date="04/01 kl. 14-15" categoryColor="blue"></MyBadge>
            <MyBadge title="Viderekommende" subtitle="Undertittel" category="Kurs" date="05/01 kl. 14-15" categoryColor="blue"></MyBadge>
    

*/