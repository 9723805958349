import { Avatar, Box, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { getRandomEmployee } from '../../../network/EmployeeProvider';

interface Props {
  text: string
}

export default function Quote(props: Props) {
  const randomEmpoyee = getRandomEmployee("Gudrun")
  return (
    <Stack
      bg={useColorModeValue('gray.50', 'gray.800')}
      py={16}
      px={8}
      spacing={{ base: 8, md: 10 }}
      align={'center'}
      direction={'column'}>
      <Text
        fontSize={{ base: 'xl', md: '2xl' }}
        textAlign={'center'}
        fontStyle={"italic"}
        maxW={'3xl'}>
        {props.text}
      </Text>
      <Box textAlign={'center'}>
        <Avatar
          src={randomEmpoyee.src}
          mb={2}
        />

        <Text fontWeight={600}>{randomEmpoyee.name}</Text>
        <Text fontSize={'sm'} color={useColorModeValue('gray.400', 'gray.400')}>
          Medgunnlegger
        </Text>
      </Box>
    </Stack>
  );
}