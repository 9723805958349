import { Box, Center, Image, Flex, Spacer } from "@chakra-ui/react";
import * as React from "react"
import Footer from "../components/Widgets/Footer";
import LayoverText from "../components/Widgets/LayoverText";
import Quote from "../components/Widgets/Shared/Quote";

import wall from "../assets/Oversikt-vegg1.jpg"

function WhatIsPage() {

  return (
    <>
      <Center>
        <Image className="rounded-borders" src={wall} w={['100%', '100%', '90%', '1000px']} marginTop={["-10px", "-10px", "40px"]} />
      </Center>
      <LayoverText shadow={true} offset={true} header="Hva er buldring?" content={[
        "Buldring er kanskje den enkleste måten å komme i gang med klatring på. Buldring er klatring på vegger i lav høyde uten tau. Det eneste du trenger er et par klatresko og kalk (som du kan leie eller kjøpe hos oss). Hvis du faller lander du på en stor trygg tjukkas som ligger fast under buldreveggene.",

        "Man trenger ingen forkunnskaper for å komme i gang med buldringen og våre ansatte hjelper deg gjerne hvis du aldri har prøvd dette før. Buldring passer for folk i alle aldre, og vi har utfordringer for alle nivåer på vårt senter."
      ]} />
      <Center>
      <Box w={['70%', '70%', '70%', '800px']}>
      <Flex>
        <Box w='40px' h='10' bg='gray.100' rounded={"full"}/>
        <Spacer />
        <Box w='40px' h='10' bg='green.500' rounded={"full"}/>
        <Spacer />
        <Box w='40px' h='10' bg='blue.500' rounded={"full"}/>
        <Spacer />
        <Box w='40px' h='10' bg='yellow.500' rounded={"full"}/>
        <Spacer />
        <Box w='40px' h='10' bg='red.500' rounded={"full"}/>
        <Spacer />
        <Box w='40px' h='10' bg='black' rounded={"full"}/>
      </Flex>
      </Box>
      </Center>
      <LayoverText shadow={false} offset={false} content={[
        "Buldrerutene er gradert etter vanskelighetsgrad. Bulderne er markert med teip, der de enkleste er hvite, etterfulgt av grønn, blå, gul, rød og svart.  De ulike rutene er plassert ved siden av hverandre, slik at man kan buldre på forskjellig vanskelighetsgrad på samme plass!",

        "For en trygg og god opplevelse på Moss klatresenter må alle sette seg inn i senterets grunnleggende sikkerhetsregler og god {$link_fallteknikk_/safety} , samt signere {$link_ansvarserklæring_/safety#terms}  før de benytter senteret."
      ]} />
      <Quote text='"For oss er sikkerheten viktigst! Trykk på "Sikkerhet"-fanen for noen tips til hvordan du kan buldre trygt."'></Quote>
      <Footer />
    </>

  )
}

export default WhatIsPage;